interface DeviceState {
	width: number
}

const BREAKPOINTS = {
	MOBILE: 768,
	TABLET: 1024,
} as const

export const useDeviceStore = defineStore('device', () => {
	const state = reactive<DeviceState>({
		width: typeof window !== 'undefined' ? window.innerWidth : 0,
	})

	const isMobile = computed(() => state.width < BREAKPOINTS.MOBILE)
	const isTablet = computed(() => state.width >= BREAKPOINTS.MOBILE && state.width < BREAKPOINTS.TABLET)
	const isDesktop = computed(() => state.width >= BREAKPOINTS.TABLET)

	const updateWidth = () => {
		if (typeof window === 'undefined') {
			return
		}
		state.width = window.innerWidth
	}

	const debounce = <T extends (...args: any[]) => any>(fn: T, ms = 300): ((...args: Parameters<T>) => void) => {
		let timeoutId: ReturnType<typeof setTimeout>

		return (...args: Parameters<T>) => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => fn(...args), ms)
		}
	}

	const debouncedUpdateWidth = debounce(updateWidth)

	// Lifecycle hooks
	if (typeof window !== 'undefined') {
		updateWidth()
	}

	onMounted(() => {
		updateWidth()
		window.addEventListener('resize', debouncedUpdateWidth)
	})

	onBeforeUnmount(() => {
		window.removeEventListener('resize', debouncedUpdateWidth)
	})

	return {
		device: {
			isMobile: isMobile.value,
			isTablet: isTablet.value,
			isDesktop: isDesktop.value,
		},
	}
})
